<template>
  <div class="license-management">
    <h2>许可证管理</h2>
    <button @click="showAddLicenseModal" class="add-btn">新增许可证</button>
    <table>
      <thead>
        <tr>
          <th>名称</th>
          <th>描述</th>
          <th>时长（天）</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="license in licenses" :key="license.id">
          <td>{{ license.name }}</td>
          <td>{{ license.description }}</td>
          <td>{{ getDurationInDays(license.duration) }}</td>
          <td>
            <button @click="editLicense(license)" class="edit-btn">修改</button>
            <button @click="showDeleteLicenseModal(license)" class="delete-btn">删除</button>
          </td>
        </tr>
      </tbody>
    </table>

    <modal v-if="showModal" @close="closeModal">
      <template #header>
        <h3>{{ modalTitle }}</h3>
      </template>
      <template #body>
        <form @submit.prevent="submitLicenseForm" class="license-form">
          <input v-model="editingLicense.name" placeholder="名称" required>
          <textarea v-model="editingLicense.description" placeholder="描述"></textarea>
          <select v-model="editingLicense.duration" required>
            <option value="7">1周 (7天)</option>
            <option value="14">2周 (14天)</option>
            <option value="30">1个月 (30天)</option>
            <option value="90">3个月 (90天)</option>
            <option value="365">1年 (365天)</option>
            <option value="permanent">永久</option>
          </select>
          <div class="form-actions">
            <button type="button" @click="closeModal" class="cancel-btn">取消</button>
            <button type="submit" class="save-btn">{{ isAdding ? '添加' : '保存' }}</button>
          </div>
        </form>
      </template>
    </modal>

    <modal v-if="showDeleteModal" @close="closeDeleteModal">
      <template #header>
        <h3>确认删除</h3>
      </template>
      <template #body>
        <p>确定要删除许可证 "{{ licenseToDelete?.name }}" 吗？</p>
        <div class="form-actions">
          <button @click="closeDeleteModal" class="cancel-btn">取消</button>
          <button @click="confirmDelete" class="delete-btn">确认删除</button>
        </div>
      </template>
    </modal>

    <div v-if="notification" class="notification" :class="notification.type">
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import axios from 'axios'
import config from '@/config'

export default {
  name: 'LicenseManagement',
  components: {
    Modal
  },
  data() {
    return {
      licenses: [],
      showModal: false,
      showDeleteModal: false,
      editingLicense: null,
      licenseToDelete: null,
      isAdding: false,
      notification: null
    }
  },
  computed: {
    modalTitle() {
      return this.isAdding ? '新增许可证' : '修改许可证';
    }
  },
  methods: {
    async fetchLicenses() {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/license`);
        this.licenses = response.data;
      } catch (error) {
        this.showMessage('获取许可证列表失败', 'error');
      }
    },
    showAddLicenseModal() {
      this.isAdding = true;
      this.editingLicense = { name: '', description: '', duration: '30' };
      this.showModal = true;
    },
    editLicense(license) {
      this.isAdding = false;
      this.editingLicense = { ...license };
      this.showModal = true;
    },
    showDeleteLicenseModal(license) {
      this.licenseToDelete = license;
      this.showDeleteModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.editingLicense = null;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.licenseToDelete = null;
    },
    async submitLicenseForm() {
      try {
        if (this.isAdding) {
          await axios.post(`${config.apiBaseUrl}/license`, this.editingLicense);
          this.showMessage('许可证添加成功');
        } else {
          await axios.put(`${config.apiBaseUrl}/license/${this.editingLicense.id}`, this.editingLicense);
          this.showMessage('许可证更新成功');
        }
        this.closeModal();
        this.fetchLicenses();
      } catch (error) {
        this.showMessage(this.isAdding ? '添加许可证失败' : '更新许可证失败', 'error');
      }
    },
    async confirmDelete() {
      try {
        await axios.delete(`${config.apiBaseUrl}/license/${this.licenseToDelete.id}`);
        this.showMessage('许可证删除成功');
        this.closeDeleteModal();
        this.fetchLicenses();
      } catch (error) {
        this.showMessage('删除许可证失败', 'error');
      }
    },
    getDurationInDays(duration) {
      if (duration === 'permanent') return '永久';
      return `${duration}天`;
    },
    showMessage(message, type = 'success') {
      this.notification = { message, type };
      setTimeout(() => {
        this.notification = null;
      }, 3000);
    }
  },
  mounted() {
    this.fetchLicenses();
  }
}
</script>

<style scoped>
.license-management {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
h2 {
  color: #333;
  margin-bottom: 20px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}
th {
  background-color: #f2f2f2;
  color: #333;
}
.add-btn, .edit-btn, .delete-btn, .save-btn, .cancel-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.add-btn {
  background-color: #4CAF50;
  color: white;
  margin-bottom: 10px;
}
.edit-btn {
  background-color: #2196F3;
  color: white;
  margin-right: 5px;
}
.delete-btn {
  background-color: #f44336;
  color: white;
}
.save-btn {
  background-color: #4CAF50;
  color: white;
}
.cancel-btn {
  background-color: #f44336;
  color: white;
  margin-right: 10px;
}
.license-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.license-form input, .license-form textarea, .license-form select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.license-form textarea {
  height: 100px;
  resize: vertical;
}
.add-btn:hover, .edit-btn:hover, .delete-btn:hover, .save-btn:hover, .cancel-btn:hover {
  opacity: 0.8;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  z-index: 1000;
}
.notification.success {
  background-color: #4CAF50;
}
.notification.error {
  background-color: #f44336;
}
</style>
