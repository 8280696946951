import * as VueRouter from 'vue-router'

// 使用解构赋值
const { createRouter, createWebHistory } = VueRouter

import HomePage from '@/components/Home.vue' // 确保路径正确
import ArticleList from '@/components/Articles.vue'
import ApiDocument from '@/components/Api.vue'
import LicenseInfo from '@/components/License.vue'
import AdminPanel from '@/components/admin/AdminPanel.vue'
import ArticleDetail from '@/components/ArticleDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/articles',
    name: 'Articles',
    component: ArticleList
  },
  {
    path: '/articles/:id',
    name: 'ArticleDetail',
    component: ArticleDetail,
    meta: { parent: 'Articles' }
  },
  {
    path: '/api',
    name: 'Api',
    component: ApiDocument
  },
  {
    path: '/license',
    name: 'License',
    component: LicenseInfo
  },
  {
    path: '/admin',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: { requiresAdmin: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 添加导航守卫以保护管理员路由
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    // 检查用户是否是管理员
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.is_admin) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
