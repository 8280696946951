<template>
  <div class="licenses main-content">
    <div class="content-container">
      <div class="license-list">
        <div v-for="license in licenses" :key="license.id" class="license-item">
          <h2 class="license-name">{{ license.name }}</h2>
          <div class="license-details">
            <p><strong>描述:</strong> {{ license.description }}</p>
            <p><strong>时长:</strong> {{ getDurationInDays(license.duration) }}</p>
          </div>
          <button @click="applyLicense(license)" class="apply-btn">申请许可证</button>
        </div>
      </div>
    </div>

    <!-- 登录提示弹窗 -->
    <modal v-if="showLoginPrompt" @close="closeLoginPrompt">
      <template #header>
        <h3>登录提示</h3>
      </template>
      <template #body>
        <div class="login-prompt-content">
          <p>请先登录再申请许可证</p>
        </div>
      </template>
      <template #footer>
        <button @click="closeLoginPrompt" class="close-btn">关闭</button>
      </template>
    </modal>

    <!-- 申请许可证的弹窗 -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal"></div>
    <div v-if="showModal" class="modal-container">
      <div class="modal-content">
        <h3>申请许可证</h3>
        <p>{{ selectedLicense.name }}</p>
        <form @submit.prevent="submitLicenseApplication">
          <input v-model="hardwareCode" placeholder="请输入硬件码" required>
          <div class="modal-actions">
            <button @click="closeModal" class="cancel-btn">取消</button>
            <button type="submit" class="confirm-btn">确认</button>
          </div>
        </form>
      </div>
    </div>

    <!-- 添加成功提示弹窗 -->
    <modal v-if="showSuccessModal" @close="closeSuccessModal">
      <template #header>
        <h3>申请成功</h3>
      </template>
      <template #body>
        <p>许可证申请成功！点击下载按钮获取许可证文件和公钥文件。</p>
      </template>
      <template #footer>
        <button @click="downloadFiles" class="download-btn">下载文件</button>
        <button @click="closeSuccessModal" class="close-btn">关闭</button>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Modal from '@/components/Modal.vue';
import config from '@/config';
import JSZip from 'jszip';

export default {
  name: 'License',
  components: {
    Modal
  },
  data() {
    return {
      licenses: [],
      showModal: false,
      showLoginPrompt: false,
      selectedLicense: null,
      hardwareCode: '',
      showSuccessModal: false,
      licenseData: null,
    };
  },
  methods: {
    async fetchLicenses() {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/license`);
        this.licenses = response.data;
      } catch (error) {
        console.error('Error fetching licenses:', error);
      }
    },
    applyLicense(license) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        this.showLoginPrompt = true;
        return;
      }
      this.selectedLicense = license;
      this.showModal = true;
    },
    closeLoginPrompt() {
      this.showLoginPrompt = false;
    },
    closeModal() {
      this.showModal = false;
      this.hardwareCode = '';
    },
    async submitLicenseApplication() {
      try {
        const response = await axios.post(`${config.apiBaseUrl}/license/apply`, {
          license_id: this.selectedLicense.id,
          hardware_code: this.hardwareCode
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.license_key && response.data.public_key) {
          this.licenseData = response.data;
          this.showSuccessModal = true;
        } else {
          this.showErrorModal('申请成功，但未收到完整的许可证信息。请检查服务器响应。');
        }
        this.closeModal();
      } catch (error) {
        console.error('Error applying for license:', error);
        if (error.response) {
          console.log('Error response:', error.response.data);
        }
        this.showErrorModal('申请许可证失败，请稍后重试。');
      }
    },
    getDurationInDays(duration) {
      if (duration === 'permanent') return '永久';
      return `${duration}天`;
    },

    saveLicenseFile(licenseKey) {
      const blob = new Blob([licenseKey], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'license.lic';
      link.click();
      URL.revokeObjectURL(link.href);
    },

    savePublicKeyFile(publicKey) {
      const blob = new Blob([publicKey], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'public.pem';
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async downloadFiles() {
      if (!this.licenseData) return;

      const zip = new JSZip();
      zip.file("license.lic", this.licenseData.license_key);
      zip.file("public.pem", this.licenseData.public_key);

      const content = await zip.generateAsync({type:"blob"});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = "license_files.zip";
      link.click();
      URL.revokeObjectURL(link.href);

      this.closeSuccessModal();
    },

    closeSuccessModal() {
      this.showSuccessModal = false;
      this.licenseData = null;
    },

    showErrorModal(message) {
      // 你可以实现一个错误弹窗，或者使用其他方式显示错误消息
      console.error(message);
    },
  },
  mounted() {
    this.fetchLicenses();
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap');

.licenses {
  background-color: #e4eff6;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}

.content-container {
  border-radius: 8px;
}

.license-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.license-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.license-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.license-name {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 700;
}

.license-details {
  flex-grow: 1;
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
}

.license-details p {
  margin: 5px 0;
}

.apply-btn {
  align-self: flex-end;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 14px;
}

.apply-btn:hover {
  background-color: #45a049;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  font-family: 'Noto Sans SC', sans-serif;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.modal-content p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
}

.modal-content input {
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 14px;
}

.modal-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Noto Sans SC', sans-serif;
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #333;
  margin-right: 10px;
}

.confirm-btn {
  background-color: #4CAF50;
  color: white;
}

.login-prompt-content {
  text-align: center;
  padding: 20px 0;
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 14px;
  color: #333;
}

.close-btn, .download-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Noto Sans SC', sans-serif;
}

.close-btn:hover, .download-btn:hover {
  background-color: #45a049;
}

.download-btn {
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .license-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .license-list {
    grid-template-columns: 1fr;
  }
}
</style>
