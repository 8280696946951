<template>
  <div class="api-management">
    <h2 class="section-title">接口管理</h2>
    <button @click="showAddAPIModal" class="add-btn">添加接口</button>
    <table>
      <thead>
        <tr>
          <th>名称</th>
          <th>描述</th>
          <th>地址</th>
          <th>请求类型</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="api in apis" :key="api.id">
          <td>{{ api.name }}</td>
          <td>{{ api.description }}</td>
          <td>{{ api.url }}</td>
          <td>{{ api.method }}</td>
          <td>
            <button @click="editAPI(api)" class="edit-btn">编辑</button>
            <button @click="deleteAPI(api.id)" class="delete-btn">删除</button>
          </td>
        </tr>
      </tbody>
    </table>

    <modal v-if="showModal" @close="closeModal">
      <template #header>
        <h3>{{ isAdding ? '添加接口' : '编辑接口' }}</h3>
      </template>
      <template #body>
        <form @submit.prevent="submitAPIForm" class="api-form">
          <input v-model="editingAPI.name" placeholder="接口名称" required>
          <input v-model="editingAPI.url" placeholder="接口地址" required>
          <select v-model="editingAPI.method" required>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
            <option value="PATCH">PATCH</option>
            <option value="HEAD">HEAD</option>
            <option value="OPTIONS">OPTIONS</option>
            <option value="TRACE">TRACE</option>
            <option value="CONNECT">CONNECT</option>
          </select>
          <textarea v-model="editingAPI.description" placeholder="接口描述"></textarea>
          <textarea v-model="editingAPI.request_body" placeholder="请求体"></textarea>
          <textarea v-model="editingAPI.example" placeholder="示例值"></textarea>
          <div class="form-actions">
            <button type="button" @click="closeModal" class="cancel-btn">取消</button>
            <button type="submit" class="save-btn">{{ isAdding ? '添加' : '保存' }}</button>
          </div>
        </form>
      </template>
    </modal>

    <!-- 添加一个小型确认弹窗 -->
    <div v-if="showDeleteConfirm" class="delete-confirm-modal">
      <div class="delete-confirm-content">
        <p>确定要删除这个接口吗？</p>
        <div class="delete-confirm-actions">
          <button @click="confirmDelete">确定</button>
          <button @click="cancelDelete">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ApiManagement',
  components: { Modal },
  setup() {
    const apis = ref([]);
    const showModal = ref(false);
    const isAdding = ref(false);
    const editingAPI = ref({});
    const showDeleteConfirm = ref(false);
    const apiToDelete = ref(null);

    const fetchAPIs = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api`);
        apis.value = response.data;
      } catch (error) {
        console.error('Error fetching APIs:', error);
      }
    };

    const showAddAPIModal = () => {
      isAdding.value = true;
      editingAPI.value = { 
        name: '', 
        url: '', 
        method: 'GET',  // 默认选择 GET 方法
        description: '', 
        request_body: '', 
        example: '' 
      };
      showModal.value = true;
    };

    const editAPI = (api) => {
      isAdding.value = false;
      editingAPI.value = { ...api };
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      editingAPI.value = {};
    };

    const submitAPIForm = async () => {
      try {
        if (isAdding.value) {
          await axios.post(`${config.apiBaseUrl}/api`, editingAPI.value);
        } else {
          await axios.put(`${config.apiBaseUrl}/api/${editingAPI.value.id}`, editingAPI.value);
        }
        closeModal();
        fetchAPIs();
      } catch (error) {
        console.error('Error submitting API:', error);
      }
    };

    const deleteAPI = (id) => {
      apiToDelete.value = id;
      showDeleteConfirm.value = true;
    };

    const confirmDelete = async () => {
      try {
        await axios.delete(`${config.apiBaseUrl}/api/${apiToDelete.value}`);
        fetchAPIs();
        showDeleteConfirm.value = false;
      } catch (error) {
        console.error('Error deleting API:', error);
      }
    };

    const cancelDelete = () => {
      showDeleteConfirm.value = false;
      apiToDelete.value = null;
    };

    onMounted(fetchAPIs);

    return {
      apis,
      showModal,
      isAdding,
      editingAPI,
      showAddAPIModal,
      editAPI,
      closeModal,
      submitAPIForm,
      deleteAPI,
      showDeleteConfirm,
      apiToDelete,
      confirmDelete,
      cancelDelete
    };
  }
};
</script>

<style scoped>
.api-management {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

.add-btn, .edit-btn, .delete-btn, .save-btn, .cancel-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-btn {
  background-color: #4CAF50;
  color: white;
  margin-bottom: 10px;
}

.edit-btn {
  background-color: #2196F3;
  color: white;
  margin-right: 5px;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.api-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.api-form input, .api-form select, .api-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.api-form textarea {
  height: 100px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

/* 增加弹窗宽度 */
:deep(.modal-container) {
  width: 50% !important;
  max-width: 1200px !important;
}

/* 防止输入框宽度被手动修改 */
.api-form input,
.api-form select,
.api-form textarea {
  width: 100% !important;
  resize: vertical; /* 只允许垂直方向调整大小 */
  box-sizing: border-box;
}

.delete-confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-confirm-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.delete-confirm-actions {
  margin-top: 15px;
}

.delete-confirm-actions button {
  margin: 0 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-confirm-actions button:first-child {
  background-color: #f44336;
  color: white;
}

.delete-confirm-actions button:last-child {
  background-color: #e0e0e0;
}
</style>
