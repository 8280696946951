<template>
  <div class="home">
    <ResourceList />
    <div class="business-cooperation">
      <h2 class="section-title">业务合作</h2>
      <div class="cooperation-items">
        <div class="cooperation-item">
          <i class="fas fa-cube"></i>
          <h3>数字孪生系统开发</h3>
          <p>构建高精度数字化模型，实现实体与虚拟系统的实时同步与交互，助力智能决策与优化</p>
        </div>
        <div class="cooperation-item">
          <i class="fas fa-puzzle-piece"></i>
          <h3>插件开发</h3>
          <p>定制化插件解决方案，扩展软件功能</p>
        </div>
        <div class="cooperation-item">
          <i class="fas fa-tools"></i>
          <h3>工具开发</h3>
          <p>专业工具定制，提升工作效率</p>
        </div>
        <div class="cooperation-item">
          <i class="fas fa-question-circle"></i>
          <h3>问题咨询</h3>
          <p>专业技术支持，解答您的疑惑</p>
        </div>
      </div>
      <div class="invitation-section">
        <h3 class="invitation-title">诚邀合作</h3>
        <p class="invitation-text">无论您是企业、个人开发者还是有创意的合作伙伴，我们都热忱欢迎您的加入。如果您对我的产品感兴趣，或者有任何创新的合作想法，请随时与我联系。</p>
        <p class="invitation-text highlight">让我们携手共创精彩未来！</p>
        <div class="contact-info">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceList from '@/components/ResourceList.vue';

export default {
  name: 'Home',
  components: {
    ResourceList
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap');

.home {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.business-cooperation {
  background-color: #e1e9e6;
  border-radius: 10px;
  padding: 20px;
}

.section-title {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.cooperation-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.cooperation-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cooperation-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.cooperation-item i {
  font-size: 2em;
  color: #4CAF50;
  margin-bottom: 10px;
}

.cooperation-item h3 {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.cooperation-item p, .invitation-section p {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
}

.invitation-section {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  margin-top: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.invitation-section:hover {
  transform: translateY(-5px);
}

.invitation-title {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.invitation-title::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #4CAF50;
}

.invitation-text {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 15px;
}

.invitation-text.highlight {
  font-weight: 700;
  color: #4CAF50;
  font-size: 18px;
}

@media (max-width: 768px) {
  .cooperation-items {
    grid-template-columns: 1fr;
  }

  .invitation-section {
    padding: 20px;
  }

  .invitation-title {
    font-size: 24px;
  }

  .invitation-text {
    font-size: 14px;
  }

  .invitation-text.highlight {
    font-size: 16px;
  }
}

.footer-nav {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 0.5rem 0;
  margin-top: 15px;
}

.footer-nav a {
  font-size: 1.5rem;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.footer-nav a:hover {
  color: #4CAF50;
}

.footer-nav a::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
}

.footer-nav a:hover::after {
  opacity: 1;
}
</style>
