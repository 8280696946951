<template>
  <div class="apis">
    <div class="api-list">
      <div v-for="api in apis" :key="api.id" class="api-item" @click="showDetails(api)">
        <h2>{{ api.name }}</h2>
        <hr>
        <div class="api-brief">
          <div class="api-method">{{ api.method }}</div>
          <div class="api-url">
            <span class="url-label">URL:</span>
            <span class="url-content"><strong>{{ getFullUrl(api.url) }}</strong></span>
          </div>
        </div>
        <hr>
        <p class="api-description">{{ truncateDescription(api.description) }}</p>
      </div>
    </div>

    <modal v-if="showModal" @close="closeModal">
      <template #header>
        <h3>{{ selectedApi.name }}</h3>
      </template>
      <template #body>
        <div class="api-details">
          <div class="api-field">
            <label>URL:</label>
            <div class="url-with-copy">
              <span>{{ getFullUrl(selectedApi.url) }}</span>
              <button @click="copyToClipboard(getFullUrl(selectedApi.url))" class="copy-button">复制</button>
            </div>
          </div>
          <div class="api-field">
            <label>方法:</label>
            <span class="api-method-detail">{{ selectedApi.method }}</span>
          </div>
          <div class="api-field">
            <label>描述:</label>
            <p>{{ selectedApi.description }}</p>
          </div>
          <div class="api-field">
            <label>请求体:</label>
            <textarea :value="selectedApi.request_body" readonly @dblclick="selectAllContent"></textarea>
          </div>
          <div class="api-field">
            <label>示例:</label>
            <textarea :value="selectedApi.example" readonly @dblclick="selectAllContent"></textarea>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import Modal from '@/components/Modal.vue';

export default {
  name: 'Api',
  components: { Modal },
  setup() {
    const apis = ref([]);
    const showModal = ref(false);
    const selectedApi = ref({});

    const fetchApis = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api`);
        apis.value = response.data;
      } catch (error) {
        console.error('Error fetching APIs:', error);
      }
    };

    const truncateDescription = (description) => {
      return description.length > 100 ? description.slice(0, 100) + '...' : description;
    };

    const showDetails = (api) => {
      selectedApi.value = api;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const selectAllContent = (event) => {
      event.target.select();
    };

    const getFullUrl = (url) => `${config.apiBaseUrl}${url}`;

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        alert('URL已复制到剪贴板');
      }).catch(err => {
        console.error('无法复制URL: ', err);
      });
    };

    onMounted(fetchApis);

    return {
      apis,
      showModal,
      selectedApi,
      truncateDescription,
      showDetails,
      closeModal,
      selectAllContent,
      getFullUrl,
      copyToClipboard
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap');

.apis {
  background-color: #e7f0e4;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}

.api-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.api-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.api-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.api-item h2 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 20px;
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 700;
}

.api-item hr {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 10px 0;
}

.api-brief {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.api-method {
  display: inline-block;
  padding: 2px 6px;
  background-color: #4CAF50;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 5px;
  align-self: flex-start;
  font-family: 'Noto Sans SC', sans-serif;
}

.api-url {
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  font-family: 'Noto Sans SC', sans-serif;
}

.url-label {
  font-weight: bold;
  margin-right: 5px;
}

.url-content {
  color: #1a73e8;
}

.url-content strong {
  font-weight: bold;
}

.api-description {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.api-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.api-field {
  display: flex;
  flex-direction: column;
}

.api-field label {
  font-weight: bold;
  margin-bottom: 5px;
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 16px;
  color: #333;
}

.api-field textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Noto Sans SC', sans-serif;
  height: 100px;
  resize: vertical;
}

.api-field p, .api-field span {
  margin: 0;
  font-size: 14px;
  line-height: 1.6;
  font-family: 'Noto Sans SC', sans-serif;
  color: #333;
}

.api-method-detail {
  display: inline-block;
  padding: 2px 6px;
  background-color: #4CAF50;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  align-self: flex-start;
  font-family: 'Noto Sans SC', sans-serif;
}

.url-with-copy {
  display: flex;
  align-items: center;
  gap: 10px;
}

.copy-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Noto Sans SC', sans-serif;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #45a049;
}

/* 增加弹窗宽度 */
:deep(.modal-container) {
  width: 80% !important;
  max-width: 800px !important;
}
</style>
