<template>
  <div id="app">
    <div class="page-container">
      <div class="app-wrapper">
        <Header 
          :isLoggedIn="isLoggedIn" 
          :username="username"
          :isAdmin="isAdmin"
          @show-login="showLoginModal"
          @logout="handleLogout"
          @open-admin="openAdminPanel"
        />
        
        <main class="main-content">
          <div class="content-wrapper">
            <router-view></router-view>
          </div>
        </main>
        
        <footer class="footer">
          <div class="content-wrapper">
            <nav class="footer-nav">
              <a href="https://github.com/Za0Shu1" target="_blank" title="GitHub">
                <i class="fab fa-github"></i>
              </a>
              <a href="https://space.bilibili.com/345139385" target="_blank" title="哔哩哔哩">
                <i class="fab fa-bilibili"></i>
              </a>
              <a href="https://www.zhihu.com/people/zaoshui-51" target="_blank" title="知乎">
                <i class="fab fa-zhihu"></i>
              </a>
              <a href="#" @click.prevent="showWechatQR" title="微信群">
                <i class="fab fa-weixin"></i>
              </a>
            </nav>
            <div class="icp-info">
              <p>
                <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023030150号-1</a>
                <img src="@/assets/icp.png" alt="ICP图标" class="icp-icon" />
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005983" target="_blank">川公网安备51019002005983</a>
              </p>
            </div>
            <p class="copyright">&copy; 2024 Za0Shu1的资源站</p>
            <p class="copyright">
              Powered by <a href="https://www.cursor.com/" target="_blank" rel="noopener noreferrer" class="cursor-link">Cursor</a>
            </p>
          </div>
        </footer>
      </div>
    </div>

    <!-- 微信二维码模态框 -->
    <div v-if="isWechatQRVisible" class="modal" @click="hideWechatQR">
      <div class="modal-content" @click.stop>
        <img :src="qrcodeUrl" alt="微信二维码" class="wechat-qr" v-if="qrcodeUrl">
        <p>扫码加群</p>
      </div>
    </div>

    <!-- 新增登录模态框 -->
    <div v-if="isLoginModalVisible" class="modal" @click="hideLoginModal">
      <div class="modal-content login-modal" @click.stop>
        <h2>{{ isRegistering ? '注册' : '登录' }}</h2>
        <form @submit.prevent="handleSubmit">
          <input v-if="isRegistering" v-model="username" type="text" placeholder="用户名" required>
          <input v-model="email" type="email" placeholder="邮箱" required>
          <input v-model="password" type="password" placeholder="密码" required>
          <button type="submit">{{ isRegistering ? '注册' : '登录' }}</button>
        </form>
        <p>
          {{ isRegistering ? '已有账号？' : '没有账号？' }}
          <a href="#" @click.prevent="toggleRegister">
            {{ isRegistering ? '登录' : '注册' }}
          </a>
        </p>
        <!-- 添加提示信息显示 -->
        <p v-if="message" :class="{ 'error-message': isError, 'success-message': !isError }">
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { provide, computed } from 'vue';
import { useStore } from 'vuex';
import Header from '@/components/Header.vue'
import config from '@/config';

export default {
  name: 'App',
  components: {
    Header
  },
  data() {
    return {
      isWechatQRVisible: false,
      isLoginModalVisible: false,
      isRegistering: false,
      isLoggedIn: false,
      username: '',
      email: '',
      password: '',
      message: '',
      isError: false,
      isAdmin: false,
      qrcodeUrl: null,
    }
  },
  computed: {
    currentUsername() {
      return this.username;
    }
  },
  created() {
    // 在应用创建时检本地存储中的录信息
    this.checkLoginStatus();
  },
  methods: {
    async showWechatQR() {
      try {
        const response = await fetch(`${config.apiBaseUrl}/common/qrcode`);
        if (response.ok) {
          const data = await response.json();
          this.qrcodeUrl = `${config.apiBaseUrl}${data.imageUrl}`;
          this.isWechatQRVisible = true;
        } else {
          console.error('Failed to fetch QR code');
        }
      } catch (error) {
        console.error('Error fetching QR code:', error);
      }
    },
    hideWechatQR() {
      this.isWechatQRVisible = false
    },
    showLoginModal() {
      this.isLoginModalVisible = true
    },
    hideLoginModal() {
      this.isLoginModalVisible = false;
      this.email = '';
      this.password = '';
      this.isRegistering = false;
      this.message = '';
    },
    toggleRegister() {
      this.isRegistering = !this.isRegistering
    },
    async handleSubmit() {
      const url = this.isRegistering ? '/auth/register' : '/auth/login';
      try {
        const response = await fetch(`${config.apiBaseUrl}${url}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: this.isRegistering ? this.username : undefined,
            email: this.email,
            password: this.password
          }),
        });

        const data = await response.json();

        if (response.ok) {
          if (this.isRegistering) {
            // 注册成功
            this.message = data.msg;
            this.isError = false;
            setTimeout(() => {
              this.isRegistering = false; // 切换到登录界面
              this.message = '';
            }, 2000);
          } else {
            // 登录成功
            this.isLoggedIn = true;
            this.username = data.user.username;
            this.isAdmin = data.user.is_admin;
            this.saveLoginInfo(data.user); // 保存登录信息
            this.hideLoginModal();
          }
        } else {
          // 这里处理非 2xx 的响应
          this.message = data.msg;
          this.isError = true;
        }
      } catch (error) {
        console.error('Error:', error);
        this.message = '发生未知错误，请稍后重试';
        this.isError = true;
      }
    },
    handleLogout() {
      this.isLoggedIn = false;
      this.username = '';
      this.isAdmin = false;
      this.message = 'You have been logged out.';
      this.isError = false;
      this.clearLoginInfo(); // 清除登录信息
      setTimeout(() => {
        this.message = '';
      }, 2000);
    },
    saveLoginInfo(user) {
      localStorage.setItem('user', JSON.stringify({
        ...user,
        created_at: user.created_at,
        last_login: user.last_login
      }));
    },
    clearLoginInfo() {
      localStorage.removeItem('user');
    },
    checkLoginStatus() {
      const userInfo = localStorage.getItem('user');
      if (userInfo) {
        const user = JSON.parse(userInfo);
        this.isLoggedIn = true;
        this.username = user.username;
        this.isAdmin = user.is_admin;
      }
    },
    openAdminPanel() {
      window.location.href = '/admin';  // 在当前窗口打开
    }
  },
  setup() {
    const store = useStore();
    
    provide('auth', {
      isAuthenticated: computed(() => store.state.auth.isAuthenticated)
    });

    // ... 其他代码
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.page-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  box-sizing: border-box;
  max-width: 1400px; /* 设置最大宽度为1400px */
  margin: 0 auto; /* 居中显示 */
  width: 100%; /* 确保在小屏幕上也能占满宽度 */
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow: visible;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 20px;    /* 添加顶部间距 */
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.footer {
  background-color: #000;
  color: #fff;
  padding: 0.5rem 0;
  width: 100%;
  border-radius: 10px;
  overflow: visible; /* 改为 visible，允许内容溢出 */
  margin-top: 20px;
  position: relative; /* 添加相对定位 */
  z-index: 1; /* 确保 footer 在其他内容之上 */
}

.footer-nav {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 0.25rem 0;
  position: relative; /* 添加相对定位 */
  z-index: 2; /* 确保导航在 footer 之上 */
}

.footer-nav a {
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}

.footer-nav a:hover {
  color: #fff;
  background-color: transparent;
}

.footer-nav a::after {
  content: attr(title);
  position: absolute;
  bottom: 150%; /* 将底部位置从 100% 调整到 150%，使 tips 显示得更高 */
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  opacity: 0;
  transition: opacity 0.3s ease, bottom 0.3s ease; /* 添加 bottom 的过渡效果 */
  pointer-events: none;
  white-space: nowrap;
  z-index: 3;
}

.footer-nav a:hover::after {
  opacity: 1;
  bottom: 160%; /* hover 时将 tips 位置稍微提高，增加视觉效果 */
}

.icp-info {
  text-align: center;
  margin: 0.5rem 0; /* 减小 ICP 信息的上下外边距 */
}

.icp-info p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 0; /* 减小段落的上下外边距 */
}

.icp-info a {
  color: #fff;
  text-decoration: none;
}

.icp-icon {
  height: 20px;
  vertical-align: middle;
}

.copyright {
  text-align: center;
  margin: 0.25rem 0; /* 减小版权信息的上下外边距 */
}

.powered-by {
  text-align: center;
  margin: 0.25rem 0;
  font-size: 0.8rem;
  color: #fff;
}

.powered-by a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.powered-by a:hover {
  color: #4CAF50;
}

@media (max-width: 1400px) {
  .page-container {
    max-width: 100%; /* 在小于1400px的屏幕上占满宽度 */
  }
  .footer {
    border-radius: 10px; /* 保持圆角 */
    margin: 0 10px 10px 10px; /* 在较小屏幕上添加一些边距，确保圆角可见 */
    width: calc(100% - 20px); /* 调整宽度以适应边距 */
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 0; /* 确保在移动设备上也没有内边距 */
  }
  .main-content {
    padding-top: 10px;    /* 在移动设备上减少顶部间距 */
    padding-bottom: 10px; /* 在移动设备上减少底部间距 */
  }
  .footer {
    border-radius: 10px; /* 在移动设备上保持圆角 */
    margin: 0 5px 5px 5px; /* 在移动设备上减少边距 */
    width: calc(100% - 10px); /* 调整宽度以适应边距 */
  }
  .icp-info p {
    flex-direction: column;
    gap: 0.15rem; /* 在移动端进一步减小间距 */
  }
  
  .footer {
    padding: 0.25rem 0; /* 在移动端进一步减小整体的上下内边距 */
  }
  .footer-nav a::after {
    font-size: 0.6rem;
    padding: 0.2rem 0.4rem;
    bottom: 130%; /* 在移动端稍微降低 tips 位置，以适应更小的屏幕 */
  }

  .footer-nav a:hover::after {
    bottom: 140%; /* 在移动端调整 hover 时的 tips 位置 */
  }
}

/* 添加新的模态框样式 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px; /* 增加内边距适应更大的图片 */
  border-radius: 15px; /* 稍微增加圆角 */
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.wechat-qr {
  max-width: 400px; /* 将最大宽度增加一倍 */
  max-height: 400px; /* 将最大��度增加一倍 */
  width: 100%; /* 确保图片不会超出容器 */
  height: auto; /* 保持宽高比 */
}

/* 添加新的登录模态框样式 */
.login-modal {
  width: 300px;
}

.login-modal h2 {
  margin-top: 0;
}

.login-modal form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-modal input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-modal button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}

.login-modal button:hover {
  background-color: #333;
}

.login-modal p {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.login-modal a {
  color: #007bff;
  text-decoration: none;
}

.login-modal a:hover {
  text-decoration: underline;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.success-message {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* 检查是否有任何全局导航样式 */
nav a {
  font-weight: bold;
  color: #2c3e50;
}

.cursor-link {
  color: #4CAF50;
  text-decoration: none;
  transition: color 0.3s ease;
}

.cursor-link:hover {
  color: #45a049;
}

</style>

