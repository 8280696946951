<template>
  <div class="common-management">
    <h2>通用管理</h2>
    <div class="section">
      <h3>微信群二维码</h3>
      <div class="qrcode-management">
        <div class="current-qrcode">
          <img v-if="currentQrcode" :src="`${currentQrcode}?t=${timestamp}`" alt="当前二维码" class="qrcode-preview">
          <p v-else>暂无二维码</p>
        </div>
        <div class="upload-section">
          <input 
            type="file" 
            ref="qrcodeInput"
            @change="handleQrcodeChange"
            accept="image/png,image/jpeg,image/jpg,image/gif"
            style="display: none"
          >
          <button @click="triggerQrcodeUpload" class="upload-btn">上传新二维码</button>
        </div>
      </div>
    </div>

    <!-- 添加通知提示 -->
    <div v-if="notification" class="notification" :class="notification.type">
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';

export default {
  name: 'CommonManagement',
  setup() {
    const currentQrcode = ref(null);
    const qrcodeInput = ref(null);
    const notification = ref(null);
    const timestamp = ref(Date.now());

    const showNotification = (message, type = 'success') => {
      notification.value = { message, type };
      setTimeout(() => {
        notification.value = null;
      }, 3000);
    };

    const fetchCurrentQrcode = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/common/qrcode`);
        if (response.data.imageUrl) {
          currentQrcode.value = `${config.apiBaseUrl}${response.data.imageUrl}`;
          timestamp.value = Date.now();
        }
      } catch (error) {
        console.error('Error fetching QR code:', error);
        showNotification('获取二维码失败', 'error');
      }
    };

    const triggerQrcodeUpload = () => {
      qrcodeInput.value.click();
    };

    const handleQrcodeChange = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('image', file);

      try {
        await axios.post(`${config.apiBaseUrl}/common/upload_qrcode`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        showNotification('二维码更新成功');
        
        setTimeout(async () => {
          currentQrcode.value = null;
          await fetchCurrentQrcode();
        }, 1000);
      } catch (error) {
        console.error('Error uploading QR code:', error);
        showNotification('二维码更新失败', 'error');
      }
      
      event.target.value = '';
    };

    onMounted(fetchCurrentQrcode);

    return {
      currentQrcode,
      qrcodeInput,
      notification,
      timestamp,
      triggerQrcodeUpload,
      handleQrcodeChange
    };
  }
};
</script>

<style scoped>
.common-management {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h2 {
  color: #333;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 30px;
}

h3 {
  color: #666;
  margin-bottom: 15px;
}

.qrcode-management {
  display: flex;
  gap: 20px;
  align-items: center;
}

.current-qrcode {
  width: 200px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.qrcode-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.upload-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-btn:hover {
  background-color: #45a049;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  z-index: 1000;
}

.notification.success {
  background-color: #4CAF50;
}

.notification.error {
  background-color: #f44336;
}
</style> 