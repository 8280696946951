<template>
  <div class="article-management">
    <h2 class="section-title">文章管理</h2>
    <button @click="showAddArticleModal" class="add-btn">添加文章</button>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>标题</th>
          <th>分类</th>
          <th>创建时间</th>
          <th>更新时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="article in articles" :key="article.id">
          <td>{{ article.id }}</td>
          <td>{{ article.title }}</td>
          <td>{{ article.category }}</td>
          <td>{{ formatDate(article.created_at) }}</td>
          <td>{{ formatDate(article.updated_at) }}</td>
          <td>
            <button @click="editArticle(article)" class="edit-btn">修改</button>
            <button @click="showDeleteArticleModal(article)" class="delete-btn">删除</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="showModal" class="modal-overlay" @click="closeModal"></div>
    <modal v-if="showModal" @close="closeModal" class="article-modal">
      <template #header>
        <h3>{{ isAdding ? '添加文章' : '修改文章' }}</h3>
      </template>
      <template #body>
        <form @submit.prevent="submitArticleForm" class="article-form">
          <input v-model="editingArticle.title" placeholder="文章标题" required class="article-title-input">
          <div class="editor-container">
            <QuillEditor 
              v-model:content="editingArticle.content" 
              contentType="html" 
              theme="snow" 
              class="quill-editor"
              :options="editorOptions"
              @ready="onEditorReady"
            />
          </div>
          <div class="form-footer">
            <input v-model="editingArticle.category" placeholder="文章分类" required class="article-category-input">
            <div class="form-actions">
              <button type="button" @click="closeModal" class="cancel-btn">取消</button>
              <button type="submit" class="save-btn">{{ isAdding ? '添加' : '保存' }}</button>
            </div>
          </div>
        </form>
      </template>
    </modal>

    <!-- 删除确认弹窗 -->
    <div v-if="showDeleteModal" class="delete-confirm-modal">
      <div class="delete-confirm-content">
        <p>确定要删除这篇文章吗？</p>
        <div class="delete-confirm-actions">
          <button @click="confirmDelete">确定</button>
          <button @click="closeDeleteModal">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Modal from '@/components/Modal.vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from 'axios';
import config from '@/config';

export default {
  name: 'ArticleManagement',
  components: {
    Modal,
    QuillEditor
  },
  setup() {
    const articles = ref([]);
    const showModal = ref(false);
    const showDeleteModal = ref(false);
    const isAdding = ref(false);
    const editingArticle = ref(null);
    const articleToDelete = ref(null);
    const categories = ref([]);

    const editorOptions = {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link', 'image', 'video']
        ]
      },
      placeholder: '请输入文章内容...',
    };

    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/articles`);
        articles.value = response.data;
        updateCategories(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    const updateCategories = (articles) => {
      const uniqueCategories = new Set(articles.map(article => article.category));
      categories.value = Array.from(uniqueCategories);
    };

    const showAddArticleModal = () => {
      isAdding.value = true;
      editingArticle.value = { title: '', category: '', content: '' };
      showModal.value = true;
    };

    const editArticle = (article) => {
      isAdding.value = false;
      editingArticle.value = { ...article };
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      editingArticle.value = null;
    };

    const submitArticleForm = async () => {
      try {
        if (isAdding.value) {
          await axios.post(`${config.apiBaseUrl}/articles`, editingArticle.value);
        } else {
          await axios.put(`${config.apiBaseUrl}/articles/${editingArticle.value.id}`, editingArticle.value);
        }
        closeModal();
        fetchArticles();
      } catch (error) {
        console.error('Error submitting article:', error);
      }
    };

    const showDeleteArticleModal = (article) => {
      articleToDelete.value = article;
      showDeleteModal.value = true;
    };

    const closeDeleteModal = () => {
      showDeleteModal.value = false;
      articleToDelete.value = null;
    };

    const confirmDelete = async () => {
      try {
        await axios.delete(`${config.apiBaseUrl}/articles/${articleToDelete.value.id}`);
        closeDeleteModal();
        fetchArticles();
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    const onEditorReady = (quill) => {
      const toolbar = quill.getModule('toolbar');
      toolbar.addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
          const file = input.files[0];
          const formData = new FormData();
          formData.append('image', file);

          try {
            const response = await fetch(`${config.apiBaseUrl}/common/upload_image`, {
              method: 'POST',
              body: formData
            });
            const result = await response.json();
            if (response.ok) {
              const imageUrl = result.imageUrl;
              const range = quill.getSelection();
              quill.insertEmbed(range.index, 'image', imageUrl);
            } else {
              console.error('图片上传失败:', result.error);
            }
          } catch (error) {
            console.error('图片上传失败:', error);
          }
        };
      });
    };

    onMounted(fetchArticles);

    return {
      articles,
      showModal,
      showDeleteModal,
      isAdding,
      editingArticle,
      articleToDelete,
      categories,
      editorOptions,
      showAddArticleModal,
      editArticle,
      closeModal,
      submitArticleForm,
      showDeleteArticleModal,
      closeDeleteModal,
      confirmDelete,
      formatDate,
      onEditorReady
    };
  }
};
</script>

<style scoped>
.article-management {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

.add-btn, .edit-btn, .delete-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-btn {
  background-color: #4CAF50;
  color: white;
  margin-bottom: 10px;
}

.edit-btn {
  background-color: #2196F3;
  color: white;
  margin-right: 5px;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.article-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.article-modal :deep(.modal-container) {
  width: 90% !important;
  max-width: 1200px !important;
  height: 90vh !important;
  display: flex !important;
  flex-direction: column !important;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.article-modal :deep(.modal-header) {
  padding: 15px 20px !important;
  border-bottom: 1px solid #e0e0e0;
}

.article-modal :deep(.modal-body) {
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 20px !important;
  overflow: hidden !important;
}

.article-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.article-title-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.editor-container {
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  height: 400px; /* 设置固定高度 */
}

.quill-editor {
  height: 100%; /* 使编辑器填满容器 */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

:deep(.quill-editor .ql-container) {
  flex-grow: 1;
  overflow-y: auto;
  border: 1px solid #ccc !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

:deep(.quill-editor .ql-toolbar) {
  border: 1px solid #ccc !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

:deep(.quill-editor .ql-toolbar, .quill-editor .ql-container) {
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px;
}

.article-category-input {
  width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.form-actions {
  display: flex;
  gap: 10px;
}

.cancel-btn, .save-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

/* 添加遮罩层样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* 修改删除确认���窗的样式 */
.article-modal.delete-confirm :deep(.modal-container) {
  width: 300px !important;
  height: auto !important;
  max-height: 200px !important;
}

.article-modal.delete-confirm :deep(.modal-body) {
  padding: 15px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.article-modal.delete-confirm p {
  margin-bottom: 15px;
}

.delete-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.delete-actions button {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-actions .cancel-btn {
  background-color: #f0f0f0;
  color: #333;
}

.delete-actions .confirm-btn {
  background-color: #f44336;
  color: white;
}

.delete-confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-confirm-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.delete-confirm-actions {
  margin-top: 15px;
}

.delete-confirm-actions button {
  margin: 0 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-confirm-actions button:first-child {
  background-color: #f44336;
  color: white;
}

.delete-confirm-actions button:last-child {
  background-color: #e0e0e0;
}
</style>
