<template>
  <div class="admin-panel">
    <div class="sidebar">
      <ul>
        <li @click="setActiveMenu('common-management')" :class="{ active: activeMenu === 'common-management' }">通用管理</li>
        <li @click="setActiveMenu('user-management')" :class="{ active: activeMenu === 'user-management' }">用户管理</li>
        <li @click="setActiveMenu('resource-management')" :class="{ active: activeMenu === 'resource-management' }">资源管理</li>
        <li @click="setActiveMenu('article-management')" :class="{ active: activeMenu === 'article-management' }">文档管理</li>
        <li @click="setActiveMenu('api-management')" :class="{ active: activeMenu === 'api-management' }">接口管理</li>
        <li @click="setActiveMenu('license-management')" :class="{ active: activeMenu === 'license-management' }">许可证管理</li>
      </ul>
    </div>
    <div class="content">
      <common-management v-if="activeMenu === 'common-management'"></common-management>
      <user-management v-if="activeMenu === 'user-management'"></user-management>
      <resource-management v-if="activeMenu === 'resource-management'"></resource-management>
      <article-management v-if="activeMenu === 'article-management'"></article-management>
      <api-management v-if="activeMenu === 'api-management'"></api-management>
      <license-management v-if="activeMenu === 'license-management'"></license-management>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import CommonManagement from './CommonManagement.vue';
import UserManagement from './UserManagement.vue';
import ResourceManagement from './ResourceManagement.vue';
import ArticleManagement from './ArticleManagement.vue';
import ApiManagement from './ApiManagement.vue';
import LicenseManagement from './LicenseManagement.vue';

export default {
  name: 'AdminPanel',
  components: {
    CommonManagement,
    UserManagement,
    ResourceManagement,
    ArticleManagement,
    ApiManagement,
    LicenseManagement
  },
  setup() {
    const activeMenu = ref('common-management');

    const setActiveMenu = (menu) => {
      activeMenu.value = menu;
    };

    return {
      activeMenu,
      setActiveMenu
    };
  }
};
</script>

<style scoped>
.admin-panel {
  display: flex;
  height: calc(100vh - 60px); /* 假设 Header 高度为 60px */
  background-color: #f5f5f5;
}
.sidebar {
  width: 200px; /* 固定左侧菜单宽度 */
  min-width: 200px; /* 确保不会被压缩 */
  background-color: #000000;
  color: white;
  padding: 20px 0;
  border-radius: 10px 0 0 10px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  overflow-y: auto; /* 如果菜单项太多，允许滚动 */
}
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar li {
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-left: 4px solid transparent;
  white-space: nowrap; /* 防止文字换行 */
  overflow: hidden;
  text-overflow: ellipsis; /* 文字过长时显示省略号 */
}
.sidebar li:hover, .sidebar li.active {
  background-color: #333333;
  border-left-color: #ffffff;
}
.content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
</style>
