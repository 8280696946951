<template>
  <header class="header">
    <div class="header-content">
      <div class="header-main">
        <h1 class="site-title" @click="goToHome">
          <span class="title-animation">Za0Shu1的资源站</span>
        </h1>
        <button class="menu-toggle" @click="toggleMenu">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </button>
      </div>
      <div class="header-menu" :class="{ 'active': menuActive }">
        <div class="header-center">
          <nav class="nav-menu">
            <router-link to="/" class="nav-item" :class="{ active: isActive('Home') }" @click="closeMenu">首页</router-link>
            <router-link to="/articles" class="nav-item" :class="{ active: isActive('Articles') || isActive('ArticleDetail') }" @click="closeMenu">文档</router-link>
            <router-link to="/api" class="nav-item" :class="{ active: isActive('Api') }" @click="closeMenu">接口</router-link>
            <router-link to="/license" class="nav-item" :class="{ active: isActive('License') }" @click="closeMenu">许可证</router-link>
          </nav>
        </div>
        <div class="header-right">
          <template v-if="!isLoggedIn">
            <button @click="showLogin" class="btn login-btn">登录</button>
          </template>
          <template v-else>
            <div class="user-actions">
              <span class="user-info">欢迎，{{ username }}</span>
              <button v-if="isAdmin" @click="openAdmin" class="btn admin-btn" title="进入后台">
                <i class="fas fa-cog"></i>
              </button>
              <button @click="logout" class="btn logout-btn">注销</button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    isLoggedIn: Boolean,
    username: String,
    isAdmin: Boolean
  },
  data() {
    return {
      menuActive: false
    }
  },
  methods: {
    goToHome() {
      this.$router.push('/');
      this.closeMenu();
    },
    isActive(routeName) {
      return this.$route.name === routeName || 
             (routeName === 'Articles' && this.$route.name === 'ArticleDetail');
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    closeMenu() {
      this.menuActive = false;
    },
    showLogin() {
      this.$emit('show-login');
      this.closeMenu();
    },
    openAdmin() {
      this.$emit('open-admin');
      this.closeMenu();
    },
    logout() {
      this.$emit('logout');
      this.closeMenu();
    }
  }
}
</script>

<style scoped>
.header {
  background-color: #000;
  color: #fff;
  padding: 10px 0;
  border-radius: 10px;
}

.header-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-main {
  display: flex;
  align-items: center;
}

.site-title {
  font-size: 1.5rem;
  margin: 0;
  cursor: pointer;
}

.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 60px; /* 增加与标题的距离 */
}

.header-center {
  flex: 1;
}

.nav-menu {
  display: flex;
}

.nav-item {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  display: inline-block; /* 使元素宽度适应内容 */
}

.nav-item:hover, .nav-item.active {
  background-color: #fff;
  color: #000;
}

.btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.admin-btn {
  background-color: #28a745;
}

.user-actions {
  display: flex;
  align-items: center;
}

.user-info {
  margin-right: 10px;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: stretch;
  }

  .header-main {
    justify-content: space-between;
  }

  .header-menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }

  .header-menu.active {
    display: flex;
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .nav-item {
    margin: 5px 0;
    width: auto; /* 移除全宽设置 */
  }

  .header-right {
    margin-top: 10px;
    width: 100%;
  }

  .user-actions {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
  }

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: white;
  }

  .header-menu {
    margin-left: 0; /* 在移动端移除额外的左边距 */
  }
}
</style>
