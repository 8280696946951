<template>
  <div class="resource-management">
    <h2>资源管理</h2>
    <button @click="showAddResourceModal" class="add-btn">添加资源</button>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th class="id-column">ID</th>
            <th class="name-column">名称</th>
            <th class="image-column">图片</th>
            <th class="description-column">描述</th>
            <th class="links-column">下载链接</th>
            <th class="date-column">创建时间</th>
            <th class="date-column">更新时间</th>
            <th class="action-column">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resource in resources" :key="resource.id">
            <td class="id-column">{{ resource.id }}</td>
            <td class="name-column truncate" :title="resource.name">{{ resource.name }}</td>
            <td class="image-column">
              <img v-if="resource.image_path" :src="getImageUrl(resource.image_path)" alt="资源图片" class="resource-image">
            </td>
            <td class="description-column truncate" :title="resource.description">
              {{ resource.description }}
            </td>
            <td class="links-column truncate" :title="JSON.stringify(resource.download_links, null, 2)">
              {{ JSON.stringify(resource.download_links) }}
            </td>
            <td class="date-column truncate" :title="formatDate(resource.created_at)">
              {{ formatDate(resource.created_at) }}
            </td>
            <td class="date-column truncate" :title="formatDate(resource.updated_at)">
              {{ formatDate(resource.updated_at) }}
            </td>
            <td class="action-column">
              <button @click="editResource(resource)" class="edit-btn">修改</button>
              <button @click="showDeleteResourceModal(resource)" class="delete-btn">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal v-if="showModal" @close="closeModal">
      <template #header>
        <h3>{{ isAdding ? '添加资源' : '修改资源' }}</h3>
      </template>
      <template #body>
        <form @submit.prevent="submitResourceForm" class="resource-form">
          <input v-model="editingResource.name" placeholder="资源名称" required>
          <textarea v-model="editingResource.description" placeholder="资源描述" class="description-textarea"></textarea>
          <div class="image-upload">
            <input type="file" @change="handleImageUpload" ref="fileInput" accept="image/*" style="display:none">
            <button type="button" @click="$refs.fileInput.click()" class="upload-btn">上传图片</button>
            <span v-if="editingResource.image_url">{{ getFileName(editingResource.image_url) }}</span>
          </div>
          <button @click.prevent="addNewLinkFields" class="add-link-btn">添加下载链接</button>
          <div v-for="(link, index) in downloadLinks" :key="index" class="link-item">
            <input v-model="link.version" placeholder="版本号" class="version-input">
            <input v-model="link.url" placeholder="下载链接" class="link-input">
            <button @click.prevent="removeLink(index)" class="remove-btn" title="删除">
              <img src="@/assets/delete.png" alt="删除" class="delete-icon">
            </button>
          </div>
          <div class="form-actions">
            <button type="button" @click="closeModal" class="cancel-btn">取消</button>
            <button type="submit" class="save-btn">{{ isAdding ? '添加' : '保存' }}</button>
          </div>
        </form>
      </template>
    </modal>
    <modal v-if="showDeleteModal" @close="closeDeleteModal">
      <template #header>
        <h3>确认删除</h3>
      </template>
      <template #body>
        <p>确定要删除资源 "{{ resourceToDelete?.name }}" 吗？</p>
        <div class="form-actions">
          <button @click="closeDeleteModal" class="cancel-btn">取消</button>
          <button @click="confirmDelete" class="delete-btn">确认删除</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import axios from 'axios'
import config from '@/config';

export default {
  name: 'ResourceManagement',
  components: {
    Modal
  },
  data() {
    return {
      resources: [],
      showModal: false,
      showDeleteModal: false,
      isAdding: false,
      editingResource: null,
      resourceToDelete: null,
      downloadLinks: [],
      imageFile: null,
    }
  },
  methods: {
    async fetchResources() {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/resources`);
        this.resources = response.data;
        console.log("Fetched resources:", this.resources);
      } catch (error) {
        console.error('Error fetching resources:', error);
        this.showMessage('获取资源列表失败', 'error');
      }
    },
    showAddResourceModal() {
      this.isAdding = true;
      this.editingResource = { name: '', description: '', image_url: '', download_links: {} };
      this.downloadLinks = [];
      this.showModal = true;
    },
    editResource(resource) {
      this.isAdding = false;
      this.editingResource = { ...resource };
      this.downloadLinks = Object.entries(resource.download_links).map(([version, url]) => ({ version, url }));
      this.showModal = true;
    },
    addNewLinkFields() {
      this.downloadLinks.push({ version: '', url: '' });
    },
    removeLink(index) {
      this.downloadLinks.splice(index, 1);
    },
    closeModal() {
      this.showModal = false;
      this.editingResource = null;
    },
    handleImageUpload(event) {
      this.imageFile = event.target.files[0];
      if (this.imageFile) {
        this.editingResource.image_url = URL.createObjectURL(this.imageFile);
      }
    },
    getFileName(url) {
      return url.split('/').pop();
    },
    async submitResourceForm() {
      try {
        const formData = new FormData();
        formData.append('name', this.editingResource.name);
        formData.append('description', this.editingResource.description);
        if (this.imageFile) {
          formData.append('image', this.imageFile);
        }
        // 正确组装 download_links
        formData.append('download_links', JSON.stringify(this.downloadLinks.reduce((acc, link) => {
          if (link.version && link.url) {
            acc[link.version] = link.url;
          }
          return acc;
        }, {})));

        console.log("Submitting resource form:", formData);

        if (this.isAdding) {
          await axios.post(`${config.apiBaseUrl}/resources`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          this.showMessage('资源添加成功');
        } else {
          await axios.put(`${config.apiBaseUrl}/resources/${this.editingResource.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          this.showMessage('资源更新成功');
        }
        this.closeModal();
        this.fetchResources();
      } catch (error) {
        console.error('Error submitting resource:', error);
        this.showMessage(this.isAdding ? '添加资源失败' : '更新资源失败', 'error');
      }
    },
    showDeleteResourceModal(resource) {
      this.resourceToDelete = resource;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.resourceToDelete = null;
    },
    async confirmDelete() {
      try {
        await axios.delete(`${config.apiBaseUrl}/resources/${this.resourceToDelete.id}`);
        this.showMessage('资源删除成功');
        this.closeDeleteModal();
        this.fetchResources();
      } catch (error) {
        console.error('Error deleting resource:', error);
        this.showMessage('删除资源失败', 'error');
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },
    showMessage(message, type = 'success') {
      // 这里可以使用一个全局的消息通知组件
      console.log(`${type}: ${message}`);
      // 例如：this.$notify({ type, message });
    },
    getImageUrl(path) {
      return `${config.apiBaseUrl}/${path}`;
    }
  },
  mounted() {
    this.fetchResources();
  }
}
</script>

<style scoped>
.resource-management {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: calc(100% - 40px); /* 减去左右padding的宽度 */
  margin: 0 auto; /* 居中 */
  overflow-x: auto;
}
h2 {
  color: #333;
  margin-bottom: 20px;
}
.table-container {
  width: 100%;
  overflow-x: auto;
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  table-layout: fixed;
}
th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
  border: 1px solid #ddd;
  padding: 8px;
}
th {
  background-color: #f2f2f2;
  color: #333;
}
.add-btn, .edit-btn, .delete-btn, .save-btn, .cancel-btn, .add-link-btn, .remove-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.add-btn {
  background-color: #4CAF50;
  color: white;
  margin-bottom: 10px;
}
.edit-btn {
  background-color: #2196F3;
  color: white;
  margin-right: 5px;
}
.delete-btn {
  background-color: #f44336;
  color: white;
}
.save-btn {
  background-color: #4CAF50;
  color: white;
}
.cancel-btn {
  background-color: #f44336;
  color: white;
  margin-right: 10px;
}
.add-link-btn {
  background-color: #2196F3;
  color: white;
  margin-top: 10px;
}
.remove-btn {
  padding: 0;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.resource-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.resource-form input,
.resource-form textarea,
.resource-form .add-link-btn {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
.description-textarea {
  resize: vertical;
  min-height: 100px;
  max-height: 300px;
}
.link-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.version-input {
  width: 25% !important;
  flex-shrink: 0;
}
.link-input {
  flex-grow: 1;
  width: auto !important;
}
.remove-btn {
  padding: 0;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.add-link-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.resource-image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.download-links-section {
  margin-top: 20px;
}
.image-upload {
  display: flex;
  align-items: center;
  gap: 10px;
}
.upload-btn {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.id-column { width: 50px; }
.name-column { width: 80px; }
.image-column { width: 100px; }
.description-column { width: 100px; }
.links-column { width: 100px; }
.date-column { width: 100px; }
.action-column { width: 100px; }
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resource-image {
  max-width: 100%;
  max-height: 50px;
  object-fit: contain;
}
.action-column {
  white-space: nowrap;
}
.edit-btn, .delete-btn {
  padding: 5px 10px;
  margin: 2px;
}
/* Tooltip styles */
.truncate {
  position: relative;
}
.truncate:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 1;
  white-space: pre-wrap;
  max-width: 300px;
  word-wrap: break-word;
}
</style>