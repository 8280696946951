<template>
  <div class="article-detail" v-if="article">
    <button class="back-button" @click="goBack">返回</button>
    <div class="article-header">
      <h1>{{ article.title }}</h1>
      <div class="article-meta">
        <span>分类: {{ article.category }}</span>
        <span>发布时间: {{ formatDate(article.created_at) }}</span>
      </div>
    </div>
    <div class="article-content"></div>
    <div class="article-navigation">
      <div v-if="prevArticle" class="nav-item prev-article">
        <span>上一篇</span>
        <a @click="navigateToArticle(prevArticle.id)">{{ prevArticle.title }}</a>
      </div>
      <div v-if="nextArticle" class="nav-item next-article">
        <span>下一篇</span>
        <a @click="navigateToArticle(nextArticle.id)">{{ nextArticle.title }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick } from 'vue';
import axios from 'axios';
import config from '@/config';
import { useRoute, useRouter } from 'vue-router';
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  name: 'ArticleDetail',
  setup() {
    const article = ref(null);
    const prevArticle = ref(null);
    const nextArticle = ref(null);
    const route = useRoute();
    const router = useRouter();
    const quillEditor = ref(null);

    const fetchArticle = async (id) => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/articles/${id}`);
        article.value = response.data;
        fetchAdjacentArticles();
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    const fetchAdjacentArticles = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/articles`);
        const articles = response.data;
        const currentIndex = articles.findIndex(a => a.id === article.value.id);
        prevArticle.value = currentIndex > 0 ? articles[currentIndex - 1] : null;
        nextArticle.value = currentIndex < articles.length - 1 ? articles[currentIndex + 1] : null;
      } catch (error) {
        console.error('Error fetching adjacent articles:', error);
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    const goBack = () => {
      router.push({ name: 'Articles' });
    };

    const navigateToArticle = (id) => {
      router.push({ name: 'ArticleDetail', params: { id } });
    };

    const initQuill = () => {
      if (quillEditor.value) return;
      nextTick(() => {
        const quillContainer = document.querySelector('.article-content');
        if (quillContainer) {
          quillEditor.value = new Quill(quillContainer, {
            readOnly: true,
            theme: 'snow',
            modules: {
              toolbar: false
            }
          });
        }
      });
    };

    watch(() => article.value, (newArticle) => {
      if (newArticle && newArticle.content) {
        initQuill();
        nextTick(() => {
          if (quillEditor.value) {
            quillEditor.value.root.innerHTML = newArticle.content;
          }
        });
      }
    }, { immediate: true });

    onMounted(() => fetchArticle(route.params.id));

    watch(() => route.params.id, (newId) => {
      fetchArticle(newId);
    });

    return {
      article,
      prevArticle,
      nextArticle,
      formatDate,
      goBack,
      navigateToArticle,
      quillEditor
    };
  }
};
</script>

<style scoped>
.article-detail {
  background-color: #f0ede4;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: auto; /* 移除可能存在的最小高度限制 */
  height: auto; /* 确保高度可以自动增长 */
  overflow: visible; /* 允许内容溢出 */
  flex-grow: 1;
}

.back-button {
  background-color: #4CAF50; /* 绿色背景 */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.back-button:hover {
  background-color: #45a049;
}

.article-header {
  text-align: center;
  margin-bottom: 20px;
}

h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.article-meta {
  color: #666;
  font-size: 14px;
}

.article-meta span {
  margin-right: 20px;
}

.article-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0; /* 上下边距 */
  width: calc(100% - 40px); /* 考虑到父容器的padding */
  max-width: 100%;
  box-sizing: border-box;
  min-height: auto; /* 移除可能存在的最小高度限制 */
  height: auto; /* 确保高度可以自动增长 */
}

.article-content :deep(img) {
  max-width: 100%;
  height: auto;
}

.article-navigation {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.nav-item {
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
}

.prev-article {
  text-align: left;
}

.next-article {
  text-align: right;
  margin-left: auto;
}

.nav-item span {
  font-weight: bold;
  margin-bottom: 5px;
  color: #666;
}

.nav-item a {
  color: #4CAF50;
  text-decoration: none;
  cursor: pointer;
}

.nav-item a:hover {
  text-decoration: underline;
}

.ql-container {
  width: 100%;
  border: none; /* 移除 Quill 默认边框 */
}

.ql-editor {
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  min-height: auto; /* 移除可能存在的最小高度限制 */
  height: auto; /* 确保高度可以自动增长 */
}

.ql-editor img {
  max-width: 100%;
  height: auto;
}
</style>
