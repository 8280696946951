<template>
  <div class="user-management">
    <h2>用户管理</h2>
    <button @click="showAddUserModal" class="add-btn">新增用户</button>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>用户名</th>
          <th>邮箱</th>
          <th>注册时间</th>
          <th>最后登录</th>
          <th>管理员</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.id }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>{{ formatDate(user.created_at) }}</td>
          <td>{{ formatDate(user.last_login) }}</td>
          <td>{{ user.is_admin ? '是' : '否' }}</td>
          <td>
            <button @click="editUser(user)" class="edit-btn">修改</button>
            <button @click="showDeleteUserModal(user)" class="delete-btn">删除</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button @click="changePage(1)" :disabled="currentPage === 1">首页</button>
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">上一页</button>
      
      <template v-for="page in displayedPages" :key="page">
        <button 
          v-if="page !== '...'" 
          @click="changePage(page)" 
          :class="{ active: currentPage === page }"
        >
          {{ page }}
        </button>
        <span v-else>...</span>
      </template>
      
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">下一页</button>
      <button @click="changePage(totalPages)" :disabled="currentPage === totalPages">尾页</button>
      
      <span class="page-jump">
        跳转到第
        <input 
          v-model.number="jumpToPage" 
          type="number" 
          min="1" 
          :max="totalPages"
        >
        页
        <button @click="jumpTo">跳转</button>
      </span>
    </div>
    <modal v-if="showModal" @close="closeModal">
      <template #header>
        <h3>{{ modalTitle }}</h3>
      </template>
      <template #body>
        <form @submit.prevent="submitUserForm" class="user-form">
          <input v-model="editingUser.username" placeholder="用户名" required>
          <input v-model="editingUser.email" placeholder="邮箱" required>
          <input v-if="isAdding" v-model="editingUser.password" type="password" placeholder="密码" required>
          <div class="checkbox-container">
            <input type="checkbox" id="isAdmin" v-model="editingUser.is_admin">
            <label for="isAdmin">管理员</label>
          </div>
          <div class="form-actions">
            <button type="button" @click="closeModal" class="cancel-btn">取消</button>
            <button type="submit" class="save-btn">{{ isAdding ? '添加' : '保存' }}</button>
          </div>
        </form>
      </template>
    </modal>
    <modal v-if="showDeleteModal" @close="closeDeleteModal">
      <template #header>
        <h3>确认删除</h3>
      </template>
      <template #body>
        <p>确定要删除用户 "{{ userToDelete?.username }}" 吗？</p>
        <div class="form-actions">
          <button @click="closeDeleteModal" class="cancel-btn">取消</button>
          <button @click="confirmDelete" class="delete-btn">确认删除</button>
        </div>
      </template>
    </modal>
    <div v-if="notification" class="notification" :class="notification.type">
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import axios from 'axios'
import config from '@/config';

export default {
  name: 'UserManagement',
  components: {
    Modal
  },
  data() {
    return {
      users: [],
      showModal: false,
      showDeleteModal: false,
      editingUser: null,
      userToDelete: null,
      isAdding: false,
      notification: null,
      currentPage: 1,
      totalPages: 1,
      totalUsers: 0,
      perPage: 10,
      jumpToPage: 1
    }
  },
  computed: {
    modalTitle() {
      return this.isAdding ? '新增用户' : '修改用户';
    },
    displayedPages() {
      const displayed = [];
      if (this.totalPages <= 7) {
        for (let i = 1; i <= this.totalPages; i++) {
          displayed.push(i);
        }
      } else {
        if (this.currentPage <= 4) {
          for (let i = 1; i <= 5; i++) {
            displayed.push(i);
          }
          displayed.push('...', this.totalPages);
        } else if (this.currentPage >= this.totalPages - 3) {
          displayed.push(1, '...');
          for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
            displayed.push(i);
          }
        } else {
          displayed.push(1, '...');
          for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
            displayed.push(i);
          }
          displayed.push('...', this.totalPages);
        }
      }
      return displayed;
    }
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/auth/users?page=${this.currentPage}&per_page=${this.perPage}`);
        this.users = response.data.users;
        this.totalUsers = response.data.total;
        this.totalPages = response.data.pages;
        this.currentPage = response.data.page;
      } catch (error) {
        this.showMessage('获取用户列表失败', 'error');
      }
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchUsers();
      }
    },
    jumpTo() {
      if (this.jumpToPage >= 1 && this.jumpToPage <= this.totalPages) {
        this.changePage(this.jumpToPage);
      } else {
        this.showMessage('请输入有效的页码', 'error');
      }
    },
    showAddUserModal() {
      this.isAdding = true;
      this.editingUser = { username: '', email: '', password: '' };
      this.showModal = true;
    },
    editUser(user) {
      this.isAdding = false;
      this.editingUser = { ...user, is_admin: user.is_admin };
      this.showModal = true;
    },
    showDeleteUserModal(user) {
      this.userToDelete = user;
      this.showDeleteModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.editingUser = null;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.userToDelete = null;
    },
    async submitUserForm() {
      try {
        const userData = {
          username: this.editingUser.username,
          email: this.editingUser.email,
          is_admin: this.editingUser.is_admin
        };

        if (this.isAdding) {
          userData.password = this.editingUser.password;
          await axios.post('${config.apiBaseUrl}/auth/users', userData);
          this.showMessage('用户添加成功');
        } else {
          await axios.put(`${config.apiBaseUrl}/auth/users/${this.editingUser.id}`, userData);
          this.showMessage('用户更新成功');
        }
        this.closeModal();
        this.fetchUsers();
      } catch (error) {
        this.showMessage(this.isAdding ? '添加用户失败' : '更新用户失败', 'error');
      }
    },
    async confirmDelete() {
      try {
        await axios.delete(`${config.apiBaseUrl}/auth/users/${this.userToDelete.id}`);
        this.showMessage('用户删除成功');
        this.closeDeleteModal();
        this.fetchUsers();
      } catch (error) {
        this.showMessage('删除用户失败', 'error');
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      return new Date(dateString).toLocaleString();
    },
    showMessage(message, type = 'success') {
      this.notification = { message, type };
      setTimeout(() => {
        this.notification = null;
      }, 3000);
    }
  },
  mounted() {
    this.fetchUsers();
  }
}
</script>

<style scoped>
.user-management {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
h2 {
  color: #333;
  margin-bottom: 20px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}
th {
  background-color: #f2f2f2;
  color: #333;
}
.add-btn, .edit-btn, .delete-btn, .save-btn, .cancel-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.add-btn {
  background-color: #4CAF50;
  color: white;
  margin-bottom: 10px;
}
.edit-btn {
  background-color: #2196F3;
  color: white;
  margin-right: 5px;
}
.delete-btn {
  background-color: #f44336;
  color: white;
}
.save-btn {
  background-color: #4CAF50;
  color: white;
}
.cancel-btn {
  background-color: #f44336;
  color: white;
  margin-right: 10px;
}
.user-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.user-form input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.add-btn:hover, .edit-btn:hover, .delete-btn:hover, .save-btn:hover, .cancel-btn:hover {
  opacity: 0.8;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}
.cancel-btn, .save-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.cancel-btn {
  background-color: #f44336;
  color: white;
}
.save-btn {
  background-color: #4CAF50;
  color: white;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
}
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  z-index: 1000;
}
.notification.success {
  background-color: #4CAF50;
}
.notification.error {
  background-color: #f44336;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}
.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.pagination button.active {
  background-color: #45a049;
  font-weight: bold;
}
.pagination span {
  margin: 0 5px;
}
.page-jump {
  margin-left: 20px;
}
.page-jump input {
  width: 50px;
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.page-jump button {
  margin-left: 5px;
}
</style>