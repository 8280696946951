<template>
  <div class="resource-list-container">
    <h2 class="section-title">开放资源</h2>
    <div class="gallery-container">
      <button class="nav-button prev" @click="showPrevious">
        <i class="fas fa-chevron-left"></i>
      </button>
      
      <div class="resource-gallery">
        <div class="gallery-wrapper" :style="{ transform: `translateX(-${currentIndex * (100/visibleItems)}%)` }">
          <div v-for="resource in resources" :key="resource.id" class="resource-item">
            <div class="resource-image-container">
              <img v-if="resource.image_path" :src="getImageUrl(resource.image_path)" :alt="resource.name" class="resource-image">
              <img v-else src="@/assets/nopic.png" alt="No image" class="resource-image placeholder-image">
              <div class="resource-actions" v-if="Object.keys(resource.download_links).length > 0">
                <button @click="handleDownload(resource)" class="download-btn">下载</button>
              </div>
            </div>
            <div class="resource-content">
              <h2 class="resource-title">{{ resource.name }}</h2>
              <p class="resource-info">{{ resource.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <button class="nav-button next" @click="showNext">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <div class="gallery-dots">
      <span
        v-for="(resource, index) in resources"
        :key="index"
        :class="{ 
          'dot': true, 
          'active': index >= currentIndex && index < currentIndex + visibleItems 
        }"
        @click="currentIndex = Math.min(index, maxIndex)"
      ></span>
    </div>

    <modal v-if="showVersionModal" @close="closeVersionModal">
      <template #header>
        <h3>选择版本下载</h3>
      </template>
      <template #body>
        <ul class="version-list">
          <li v-for="(link, version) in selectedResource.download_links" :key="version">
            <a :href="link" target="_blank" @click="closeVersionModal">{{ version }}</a>
          </li>
        </ul>
      </template>
    </modal>

    <modal v-if="showLoginPrompt" @close="closeLoginPrompt">
      <template #header>
        <h3>登录提示</h3>
      </template>
      <template #body>
        <div class="login-prompt-content">
          <p>请先登录后再下载资源</p>
        </div>
      </template>
      <template #footer>
        <button @click="closeLoginPrompt" class="close-btn">关闭</button>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Modal from '@/components/Modal.vue';
import config from '@/config';
import noPicImage from '@/assets/nopic.png';

export default {
  name: 'ResourceList',
  components: {
    Modal
  },
  data() {
    return {
      resources: [],
      showVersionModal: false,
      showLoginPrompt: false,
      selectedResource: null,
      currentIndex: 0,
      visibleItems: 3 // 默认显示3个项目
    };
  },
  computed: {
    maxIndex() {
      return Math.max(0, this.resources.length - this.visibleItems);
    },
    totalGroups() {
      // 计算实际的组数
      return Math.ceil(Math.max(0, this.resources.length - (this.visibleItems - 1)) / this.visibleItems);
    }
  },
  methods: {
    async fetchResources() {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/resources`);
        this.resources = response.data;
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    },
    getImageUrl(path) {
      if (path) {
        return `${config.apiBaseUrl}/${path}`;
      }
      return noPicImage;
    },
    handleDownload(resource) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        this.showLoginPrompt = true;
        return;
      }
      this.showVersions(resource);
    },
    showVersions(resource) {
      this.selectedResource = resource;
      this.showVersionModal = true;
    },
    closeVersionModal() {
      this.showVersionModal = false;
      this.selectedResource = null;
    },
    closeLoginPrompt() {
      this.showLoginPrompt = false;
    },
    showPrevious() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.maxIndex;
      }
    },
    showNext() {
      if (this.currentIndex < this.maxIndex) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    updateVisibleItems() {
      if (window.innerWidth <= 768) {
        this.visibleItems = 1;
      } else {
        this.visibleItems = 3;
      }
      // 确保 currentIndex 不会超出范围
      this.currentIndex = Math.min(this.currentIndex, this.maxIndex);
    }
  },
  mounted() {
    this.fetchResources();
    this.updateVisibleItems();
    window.addEventListener('resize', this.updateVisibleItems);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateVisibleItems);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap');

.resource-list-container {
  background-color: #dbebec;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  max-height: 600px; /* 设置最大高度 */
  overflow-y: auto; /* 允许垂直滚动 */
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.gallery-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border-radius: 8px; /* 添加圆角 */
  overflow: hidden; /* 确保内容不会超出圆角边界 */
}

.resource-gallery {
  width: 100%;
  overflow: hidden;
}

.gallery-wrapper {
  display: flex;
  transition: transform 0.3s ease;
  width: 100%;
  padding: 10px 0; /* 添加上下内边距 */
}

.resource-item {
  flex: 0 0 calc((100% - 60px) / 3);
  margin: 0 10px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px; /* 保持item的圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.resource-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  z-index: 1;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.nav-button.prev {
  left: 0;
}

.nav-button.next {
  right: 0;
}

.resource-image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.resource-image {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  object-fit: cover;
  border-radius: 8px;
  margin: 8px;
}

.resource-actions {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.resource-image-container:hover .resource-actions {
  opacity: 1;
}

.download-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: #45a049;
}

.resource-content {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.resource-title {
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.resource-info {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 15px;
  flex-grow: 1;
}

.version-list {
  list-style-type: none;
  padding: 0;
}

.version-list li {
  margin-bottom: 10px;
}

.version-list a {
  display: block;
  padding: 10px;
  background-color: #f0f0f0;
  text-decoration: none;
  color: #333;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.version-list a:hover {
  background-color: #e0e0e0;
}

/* 自定义滚动条样式 */
.resource-list-container::-webkit-scrollbar {
  width: 8px;
}

.resource-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.resource-list-container::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 4px;
}

.resource-list-container::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

.login-prompt-content {
  text-align: center;
  padding: 20px 0;
}

.login-prompt-content p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.close-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.close-btn:hover {
  background-color: #45a049;
}

@media (max-width: 1024px) {
  .resource-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .resource-grid {
    grid-template-columns: 1fr;
  }

  .resource-item {
    flex: 0 0 100%;
    margin: 0;
    padding: 10px;
  }

  .resource-content {
    padding: 12px;
  }

  .resource-title {
    font-size: 1.1em;
  }

  .resource-info {
    font-size: 0.85em;
  }

  .gallery-container {
    padding: 0 20px;
  }
}

.gallery-dots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #4CAF50;
}
</style>
