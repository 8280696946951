<template>
  <div class="articles">
    <div class="category-selector">
      <button 
        @click="selectCategory('')" 
        :class="{ active: selectedCategory === '' }"
      >
        全部
      </button>
      <button 
        v-for="category in categories" 
        :key="category" 
        @click="selectCategory(category)"
        :class="{ active: selectedCategory === category }"
      >
        {{ category }}
      </button>
    </div>
    <div class="article-list">
      <div v-for="article in filteredArticles" :key="article.id" class="article-item" @click="goToArticle(article.id)">
        <h2>{{ article.title }}</h2>
        <p>{{ truncateDescription(article.content) }}</p>
        <div class="article-meta">
          <span>发布时间: {{ formatDate(article.created_at) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import config from '@/config';
import { useRouter } from 'vue-router';

export default {
  name: 'Articles',
  setup() {
    const articles = ref([]);
    const categories = ref([]);
    const selectedCategory = ref('');
    const router = useRouter();

    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/articles`);
        articles.value = response.data;
        updateCategories(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    const updateCategories = (articles) => {
      const uniqueCategories = new Set(articles.map(article => article.category));
      categories.value = Array.from(uniqueCategories);
    };

    const truncateDescription = (content) => {
      const strippedContent = content.replace(/<[^>]+>/g, '');
      return strippedContent.length > 100 ? strippedContent.slice(0, 100) + '...' : strippedContent;
    };

    const selectCategory = (category) => {
      selectedCategory.value = category;
    };

    const goToArticle = (id) => {
      router.push({ name: 'ArticleDetail', params: { id } });
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    const filteredArticles = computed(() => {
      if (selectedCategory.value === '') {
        return articles.value;
      }
      return articles.value.filter(article => article.category === selectedCategory.value);
    });

    onMounted(fetchArticles);

    return {
      articles,
      categories,
      selectedCategory,
      truncateDescription,
      selectCategory,
      goToArticle,
      formatDate,
      filteredArticles
    };
  }
};
</script>

<style scoped>
.articles {
  background-color: #f0ede4;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}

.category-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.category-selector button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.category-selector button.active {
  background-color: #4CAF50;
  color: white;
}

.article-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.article-item h2 {
  margin-top: 0;
  color: #333;
  font-size: 20px;
}

.article-item p {
  color: #666;
  font-size: 14px;
  line-height: 1.6;
}

.article-meta {
  font-size: 12px;
  color: #888;
  margin-top: 10px;
}
</style>
